import { FormValues } from '@wix/form-viewer';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  ServiceData,
  SlotService,
} from '../../../../utils/state/types';
import { BOOKINGS_APP_DEF_ID } from '../../../../utils/flow-api-adapter/consts';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';
import { isValidEmail } from '../../../../utils/form-validations';
import { LineItem } from '@wix/ambassador-totals-calculator/types';
import { FormStatus } from '../../../../types/form-state';
import { PriceInfo } from '@wix/ambassador-bookings-v2-price-info/types';
import {
  mapPriceInfoToPaymentDetails,
  mapVariantsToLineItems,
} from '../../../../utils/mappers/dynamic-price.mapper';
import { FormApi } from '../../../../api/FormApi';
import { SelectedVariants } from '@wix/bookings-uou-types';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import { generateCouponScope } from '../../../../consts/coupon';
import { getPaymentOption } from '../../../../api/implementations/getPaymentOption/getPaymentOption';
import { WixFormFields } from '../../../../types/wix-forms';

export type CalculatePaymentDetails = ({
  wixFormValues,
}: {
  wixFormValues?: FormValues;
}) => Promise<void>;

export function createCalculatePaymentDetailsAction({
  actionFactoryParams,
  errorHandlers,
}: {
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>;
  errorHandlers: ErrorHandlers;
}): CalculatePaymentDetails {
  return async ({ wixFormValues }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi, reportError } = actionFactoryParams.context;
    const { selectedNumberOfParticipants, businessInfo } = state;
    const email = wixFormValues?.[WixFormFields.EMAIL] as string;

    setState({
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    try {
      const priceInfoList: {
        [key: string]: PriceInfo;
      } = {};
      let updatedServiceData: ServiceData = { ...state.serviceData };

      await Promise.all(
        mapToArray<SlotService>(state.serviceData.slotServices).map(
          async (slotService) => {
            const { nestedSlot, dynamicPriceInfo } = slotService;
            const identifer = getServiceSlotIdentifier(slotService.nestedSlot);
            const priceInfo = await previewPrice({
              serviceId: nestedSlot.serviceId,
              resourceId: nestedSlot.resource?.id!,
              selectedVariants: dynamicPriceInfo?.selectedVariants,
              formApi,
            });

            if (priceInfo?.bookingLineItems?.length) {
              const dynamicPriceOptions =
                dynamicPriceInfo?.serviceOptionsAndVariants?.options!;
              const locale = businessInfo.dateRegionalSettingsLocale!;
              const paymentDetails = mapPriceInfoToPaymentDetails({
                priceInfo,
                dynamicPriceOptions,
                locale,
              });

              priceInfoList[identifer] = priceInfo;

              updatedServiceData = {
                ...state.serviceData,
                slotServices: {
                  ...state.serviceData.slotServices,
                  [identifer]: {
                    ...state.serviceData.slotServices[identifer],
                    dynamicPriceInfo: {
                      ...state.serviceData.slotServices[identifer]
                        .dynamicPriceInfo,
                      paymentDetails,
                    },
                  },
                },
              };
            }
          },
        ),
      );

      setState({ serviceData: updatedServiceData });

      const lineItems: LineItem[] = [];

      mapToArray<SlotService>(state.serviceData.slotServices).forEach(
        (slotService) => {
          const {
            service,
            paymentDetails,
            dynamicPriceInfo,
            selectedPaymentType,
            nestedSlot,
          } = slotService;
          const priceInfo =
            priceInfoList[getServiceSlotIdentifier(slotService.nestedSlot)];
          let price: number;
          let deposit: number | undefined;
          const isDynamicPrice = !!priceInfo?.calculatedPrice;

          if (priceInfo?.bookingLineItems?.length) {
            price =
              Number(priceInfo?.calculatedPrice) ||
              Number(dynamicPriceInfo?.defaultPrice?.amount!);
            deposit = priceInfo?.deposit!;
          } else {
            price = service.payment.paymentDetails.price;
            deposit = paymentDetails.minCharge;
          }

          const paymentOption = getPaymentOption(selectedPaymentType, deposit);

          lineItems.push({
            id: nestedSlot.lineItemId,
            catalogReference: {
              catalogItemId: service.id,
              appId: BOOKINGS_APP_DEF_ID,
            },
            price: String(
              price * (isDynamicPrice ? 1 : selectedNumberOfParticipants),
            ),
            ...(deposit
              ? {
                  depositAmount: String(
                    deposit *
                      (isDynamicPrice ? 1 : selectedNumberOfParticipants),
                  ),
                }
              : {}),
            quantity: 1,
            couponScopes: [generateCouponScope(service.id)],
            paymentOption,
          });
        },
      );

      const { priceSummary, payNow, payLater } =
        await formApi.calculateTotalPrice({
          lineItems,
          email: isValidEmail(email) ? email : undefined,
          onError: (error) => {
            errorHandlers.addError(error);
          },
        });

      const slotServicesTotalPrice = mapToArray<SlotService>(
        state.serviceData.slotServices,
      ).reduce((partialSum, a) => partialSum + a.paymentDetails.price, 0);

      setState({
        serviceData: {
          ...state.serviceData,
          summaryPaymentDetails: {
            subtotalPerParticipant:
              Number(priceSummary?.subtotal?.amount) /
              selectedNumberOfParticipants,
            tax: Number(priceSummary?.tax?.amount),
            totalPrice: priceSummary?.total?.amount
              ? Number(priceSummary?.total?.amount)
              : slotServicesTotalPrice,
            payNow: Number(payNow?.total?.amount),
            payLater: payLater?.total?.amount
              ? Number(payLater?.total?.amount)
              : state.serviceData.summaryPaymentDetails.payLater,
          },
        },
        status: FormStatus.IDLE,
      });
    } catch (error) {
      errorHandlers.addError(error as any);
      setState({ status: FormStatus.IDLE });
      reportError(error as any);
    }
  };
}

async function previewPrice({
  formApi,
  serviceId,
  resourceId,
  selectedVariants,
}: {
  formApi: FormApi;
  serviceId: string;
  resourceId: string;
  selectedVariants?: SelectedVariants[];
}): Promise<PriceInfo> {
  if (selectedVariants) {
    const bookingLineItems = mapVariantsToLineItems({
      serviceId,
      resourceId,
      selectedVariants,
    });
    if (bookingLineItems.length) {
      const previewPriceResponse = await formApi.previewPrice({
        bookingLineItems,
      });
      return previewPriceResponse?.priceInfo!;
    }
  }
  return {};
}
