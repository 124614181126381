import {
  GetActiveFeaturesResponse,
  GetAggregatedInfoResponse,
} from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import {
  NotificationType,
  GetNotificationViewResponse,
} from '@wix/ambassador-bookings-notifications-v1-notifications-settings/types';
import { mapResponseToBusinessInfo } from './business-info.mapper';
import { ActiveFeatures } from '@wix/bookings-uou-types';
import { BusinessInfo } from '../../types/types';
import { IFlowApiAdapter } from '../flow-api-adapter/types';

export const mapBusinessResponseToBusinessInfo = ({
  businessResponse,
  notificationSetupViewResponse: notificationViewResponse,
  wixSdkAdapter,
}: {
  businessResponse: GetAggregatedInfoResponse;
  notificationSetupViewResponse: GetNotificationViewResponse;
  wixSdkAdapter: IFlowApiAdapter;
}): BusinessInfo => {
  const businessInfoBase = mapResponseToBusinessInfo(businessResponse);
  return {
    ...businessInfoBase,
    dateRegionalSettingsLocale: wixSdkAdapter.getDateRegionalSettingsLocale(),
    language: wixSdkAdapter.getSiteLanguage(),
    isSMSReminderEnabled: isSMSReminderEnabled(
      businessResponse,
      notificationViewResponse,
    ),
    activeFeatures: mapActiveFeatures(businessResponse.activeFeatures),
  };
};

const isSMSReminderEnabled = (
  businessResponse: GetAggregatedInfoResponse,
  notificationSetupResponse: GetNotificationViewResponse,
): boolean => {
  const applicableForSmsReminders =
    !!businessResponse.activeFeatures?.applicableForSmsReminders;
  const isUserEnabledSMSReminder =
    !!notificationSetupResponse?.notifications?.find(
      (notification) =>
        notification.isEnabled &&
        notification.type === NotificationType.REMINDER_SMS &&
        notification.requireParticipantConsent,
    );
  return applicableForSmsReminders && isUserEnabledSMSReminder;
};

const mapActiveFeatures = (
  activeFeatures?: GetActiveFeaturesResponse,
): ActiveFeatures => {
  return {
    payments: !!activeFeatures?.applicableForPayments,
    groups: !!activeFeatures?.applicableForGroups,
    reminders: !!activeFeatures?.applicableForReminders,
    smsReminders: !!activeFeatures?.applicableForSmsReminders,
    staffMembers: true,
    course: !!activeFeatures?.applicableForCourse,
    individual: !!activeFeatures?.applicableForIndividual,
    staffLimit: activeFeatures?.bookingsStaffLimit!,
  };
};
