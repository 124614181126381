import { ControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';
import { CartModalStatus, TFunction } from '../../types/types';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { FormApi } from '../../api/FormApi';
import { EmptyStateErrorType } from '../../types/errors';
import { getErrorByType } from '../errors/errors';
import { FormStatus } from '../../types/form-state';
import { getPageAPIData } from '../../api/PageAPIAdapter/pageApiAdapter';
import { isDayfulFlow } from '../validations/dayful';
import { isCartEnabled } from './utils/utils';
import { fetchInitialData } from './dataFetchers/fetchInitialData';
import { isServiceExist } from '../validations/Validations';
import { FormState, SlotService } from './types';
import { renderNoIndexMetaTag } from '../seo/seo';
import { mapNestedSlotToServices } from './mapNestedSlotToServices/mapNestedSlotToServices';
import { getDummyData } from './dataFetchers/dummyDataFetcher';
import { IFlowApiAdapter } from '../flow-api-adapter/types';
import { calculateWixFormInitialValues } from './calculateWixFormInitialValues/calculateWixFormInitialValues';
import { getFormId } from './getFormId/getFormId';
import { checkBookingConflictsAndPushErrors } from './checkBookingConflictsAndPushErrors/checkBookingConflictsAndPushErrors';

export async function createInitialState({
  currentState,
  t,
  flowApi,
  wixSdkAdapter,
  bookingsDataCapsule,
  formApi,
  reportError,
}: {
  currentState: Partial<FormState>;
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: IFlowApiAdapter;
  bookingsDataCapsule: BookingsDataCapsule;
  formApi: FormApi;
  reportError: ReportError;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;

  if (wixSdkAdapter.isSSR()) {
    return {
      status: FormStatus.SSR,
    } as FormState;
  }

  try {
    const formPageData = await getPageAPIData({
      formApi,
      flowAPI: flowApi,
    });

    if (
      wixSdkAdapter.isEditorMode() ||
      (wixSdkAdapter.isPreviewMode() &&
        !isServiceExist(formPageData?.formSelectedSlot))
    ) {
      return await getDummyData(
        formApi,
        wixSdkAdapter,
        flowApi,
        flowApi.experiments,
      );
    }

    if (!formPageData) {
      throw EmptyStateErrorType.INVALID_PAGE_API_DATA;
    }

    const {
      areCouponsAvailable,
      catalogData,
      benefitsWithPlanInfo,
      publicPlans,
      numberOfSessions,
      formSelectedSlot,
      memberships,
      isPricingPlanInstalled,
      isSMSAutomationsEnabled,
      isMemberAreaInstalled,
      errors,
      bookingsLineItemOptions,
      serviceOptionsAndVariants,
      memberDetails,
      isUpsellPluginInstalled,
      fieldProperties = {},
    } = await fetchInitialData({
      formApi,
      controllerConfig,
      wixSdkAdapter,
      formSelectedSlot: formPageData.formSelectedSlot,
      timezone: formPageData.timezone,
      experiments: flowApi.experiments,
      reportError,
    });

    renderNoIndexMetaTag(wixSdkAdapter);

    const emptyStateError = getErrorByType({
      errorType: EmptyStateErrorType,
      errors,
    });

    if (emptyStateError) {
      throw emptyStateError.errorType;
    }

    const isCart = await isCartEnabled({
      experiments: flowApi.experiments,
      wixSdkAdapter,
      businessInfo: catalogData!.businessInfo,
    });

    const {
      controllerConfig: { wixCodeApi },
      environment: { isPreview },
    } = flowApi;

    const isDayful = await isDayfulFlow(wixCodeApi, isPreview);

    const isServiceInCart = (bookingsLineItemOptions?.length! || 0) > 0;

    const summaryPaymentDetails = {
      totalPrice: 0,
      payLater: 0,
    };

    const {
      slotServices,
      firstSlotServices,
    }: {
      slotServices: { [key: string]: SlotService };
      firstSlotServices: SlotService;
    } = mapNestedSlotToServices({
      formSelectedSlot,
      catalogData,
      memberships,
      numberOfSessions: numberOfSessions!,
      serviceOptionsAndVariants,
      formPageData,
      bookingsLineItemOptions,
      isCart,
      formApi,
      wixSdkAdapter,
      settings,
      isPricingPlanInstalled,
      t,
      summaryPaymentDetails,
      publicPlans,
      benefitsWithPlanInfo,
    });

    const wixFormInitialValues = await calculateWixFormInitialValues({
      currentState,
      bookingsDataCapsule,
      memberDetails,
      bookingsLineItemOptions,
      fieldProperties,
    });

    checkBookingConflictsAndPushErrors({
      formSelectedSlot,
      firstSlotServices,
      bookingsLineItemOptions,
      errors,
    });

    const formId = getFormId({ catalogData });

    return {
      serviceData: {
        formId,
        slotServices,
        summaryPaymentDetails,
        isSingleService: Object.keys(slotServices).length === 1,
        maxNumberOfParticipants:
          firstSlotServices.service.maxNumberOfParticipants,
        maxNumberOfParticipantsWithoutPP:
          firstSlotServices.service.maxNumberOfParticipantsWithoutPP,
      },
      wixForms: {
        initialValues: wixFormInitialValues.initialValues,
        overrides: wixFormInitialValues.overrides,
        fieldProperties,
      },
      selectedNumberOfParticipants: 1,
      activeFeatures: catalogData!.activeFeatures,
      businessInfo: catalogData!.businessInfo,
      formSelectedSlot: formSelectedSlot!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      isMemberAreaInstalled: isMemberAreaInstalled!,
      isUpsellPluginInstalled: isUpsellPluginInstalled!,
      ...(isCart
        ? {
            isCart,
            isServiceInCart,
          }
        : {}),
      errors,
      couponInfo: {
        areCouponsAvailable: areCouponsAvailable!,
      },
      editorContext: {
        isDummy: false,
      },
      status: currentState.status || FormStatus.INITIALIZING,
      dialog: undefined,
      cartModal: {
        status: CartModalStatus.CLOSED,
        lineItems: [],
      },
      isDayful,
      ...(bookingsLineItemOptions?.length ? { bookingsLineItemOptions } : {}),
      memberDetails,
      isSMSAutomationsEnabled,
    };
  } catch (formError) {
    return {
      errors: [formError],
    } as FormState;
  }
}
