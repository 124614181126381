import { AddToCartErrorType } from '../../../../types/errors';
import { CreateActionParams } from '../actions';

export type OnClickViewCart = () => Promise<void>;

export function createOnClickViewCartAction({ context }: CreateActionParams) {
  return async () => {
    const { wixSdkAdapter } = context;
    await wixSdkAdapter.navigateToEcomCart();
  };
}

export type OnClickCloseCartErrorModal = () => void;

export function createOnClickCloseCartErrorModalAction({
  internalActions,
}: CreateActionParams) {
  return () => {
    internalActions.errorHandlers.clearErrorByTypes([AddToCartErrorType]);
  };
}
