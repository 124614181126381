import moment from 'moment-timezone';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isInputValid } from './validateURLInput';
import { getURLAdapter } from './URLAdapter';
import { FormApi } from '../../FormApi';
import {
  Location,
  LocationType,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { MultiDynamicPriceInfo } from '@wix/bookings-uou-types';
import { resolveSessionStorageData } from '../SessionStorageAdapter/SessionStorageResolver';
import { PageAPIData } from '../../../types/types';
import { getInputServiceType } from './utils';
import { Location as SessionLocation } from '@wix/ambassador-bookings-calendar-v1-session/types';
import {
  Location as EventLocation,
  LocationType as EventLocationType,
} from '@wix/ambassador-calendar-v3-event/types';
import { STAFF_RESOURCE_TYPE_ID } from '../../../consts/resource';

export const resolveURLData = async ({
  flowAPI,
  formApi,
}: {
  flowAPI: ControllerFlowAPI;
  formApi: FormApi;
}): Promise<PageAPIData | undefined> => {
  let formData: PageAPIData | undefined;
  const urlAdapter = getURLAdapter({ flowAPI });
  const isValid = isInputValid({ flowAPI });

  if (!isValid) {
    // URL API is active, but there is no data. We will try to resolve the session storage instead
    formData = await resolveSessionStorageData({
      flowAPI,
    });
  }

  const serviceType = getInputServiceType({ flowAPI });
  const sessionId = urlAdapter.getSessionId();
  const serviceId = urlAdapter.getServiceId();
  const eventId = urlAdapter.getEventId();
  const resourceId = urlAdapter.getResourceId();
  const locationId = urlAdapter.getLocationId();
  const startDate = urlAdapter.getStartDate();
  const endDate = urlAdapter.getEndDate();
  const timezone = urlAdapter.getTimezone()!;
  const dynamicPricePreSelection = urlAdapter.getDynamicPricePreSelection(
    serviceId || formData?.slotAvailability?.slot?.serviceId!,
    startDate || formData?.slotAvailability?.slot?.startDate!,
  );

  if (serviceType === 'class') {
    if (eventId) {
      formData = await resolveByEventId({
        formApi,
        eventId: eventId!,
        dynamicPricePreSelection,
        timezone,
      });
    } else {
      formData = await resolveBySessionId({
        formApi,
        sessionId: sessionId!,
        dynamicPricePreSelection,
        timezone,
      });
    }
  }

  if (serviceType === 'appointment') {
    formData = await resolveForAppointment({
      formApi,
      serviceId: serviceId!,
      resourceId: resourceId!,
      locationId: locationId!,
      startDate: startDate!,
      endDate: endDate!,
      timezone,
      dynamicPricePreSelection,
    });
  }

  if (serviceType === 'course') {
    formData = await resolveCourseData({
      serviceId: serviceId!,
      timezone,
      dynamicPricePreSelection,
    });
  }

  return formData;
};

export const resolveCourseData = async ({
  serviceId,
  timezone,
  dynamicPricePreSelection,
}: {
  serviceId: string;
  timezone: string;
  dynamicPricePreSelection?: MultiDynamicPriceInfo;
}): Promise<PageAPIData | undefined> => {
  return {
    serviceId,
    timezone,
    dynamicPricePreSelection,
  };
};

export const resolveForAppointment = async ({
  formApi,
  serviceId,
  resourceId,
  locationId,
  startDate,
  endDate,
  timezone,
  dynamicPricePreSelection,
}: {
  formApi: FormApi;
  serviceId: string;
  resourceId: string;
  locationId: string;
  startDate: string;
  endDate: string;
  timezone: string;
  dynamicPricePreSelection?: MultiDynamicPriceInfo;
}): Promise<PageAPIData | undefined> => {
  // checking availability with the beginning of day as start date to overcome a potential problem with slot availability, in case selected slot was moved due to selection of other slot
  const beginningOfStartDate = moment(startDate)
    .tz(timezone)
    .startOf('day')
    .toISOString();

  const [availability] = await Promise.all([
    formApi.getAppointmentAvailability({
      serviceId,
      resourceId,
      locationId,
      startDate,
      endDate,
      timezone,
      beginningOfStartDate,
    }),
  ]);

  return {
    serviceId,
    timezone,
    dynamicPricePreSelection,
    slotAvailability: availability,
  };
};

export const resolveByEventId = async ({
  formApi,
  eventId,
  timezone,
  dynamicPricePreSelection,
}: {
  formApi: FormApi;
  eventId: string;
  timezone: string;
  dynamicPricePreSelection?: MultiDynamicPriceInfo;
}): Promise<PageAPIData | undefined> => {
  const event = await formApi.getEventById({ eventId });

  if (!event) {
    throw new Error(
      `ERROR: unable to resolve URL data, failed to fetch event for id ${eventId}`,
    );
  }

  const staffMember = event.resources?.find(
    (resource) => resource.type === STAFF_RESOURCE_TYPE_ID,
  );

  const pageData: PageAPIData = {
    timezone,
    serviceId: event.externalScheduleId as string,
    slotAvailability: {
      openSpots: event.remainingCapacity || 1,
      slot: {
        eventId,
        serviceId: event.externalScheduleId!,
        scheduleId: event.scheduleId!,
        startDate: event.start?.utcDate?.toISOString()!,
        endDate: event.end?.utcDate?.toISOString()!,
        timezone,
        location: mapEventLocationToLocation(event.location!) as Location,
        resource: {
          id: staffMember?.id!,
          name: staffMember?.name!,
          scheduleId: staffMember?.scheduleId!,
        },
      },
    },
    dynamicPricePreSelection,
  };

  return pageData;
};

export const resolveBySessionId = async ({
  formApi,
  sessionId,
  timezone,
  dynamicPricePreSelection,
}: {
  formApi: FormApi;
  sessionId: string;
  timezone: string;
  dynamicPricePreSelection?: MultiDynamicPriceInfo;
}): Promise<PageAPIData | undefined> => {
  const session = await formApi.getSessionById({ sessionId });

  if (!session) {
    throw new Error(
      `ERROR: unable to resolve URL data, failed to fetch session for id ${sessionId}`,
    );
  }

  const pageData: PageAPIData = {
    timezone,
    serviceId: session.scheduleOwnerId!,
    slotAvailability: {
      openSpots: Math.max(
        session.capacity! - session.totalNumberOfParticipants!,
        0,
      ),
      slot: {
        sessionId,
        serviceId: session.scheduleOwnerId!,
        scheduleId: session.scheduleId,
        startDate: session.start?.timestamp?.toISOString(),
        endDate: session.end?.timestamp?.toISOString(),
        timezone,
        location: mapSessionLocationToLocation(session.location!) as Location,
        resource: {
          id: session.affectedSchedules?.[0].scheduleOwnerId,
          name: session.affectedSchedules?.[0].scheduleOwnerName as string,
          scheduleId: session.affectedSchedules?.[0].scheduleId,
        },
      },
    },
    dynamicPricePreSelection,
  };

  return pageData;
};

const mapEventLocationToLocation = (eventLocation: EventLocation): Location => {
  let locationType = LocationType.UNDEFINED;

  switch (eventLocation.type) {
    case EventLocationType.BUSINESS:
      locationType = LocationType.OWNER_BUSINESS;
      break;
    case EventLocationType.CUSTOM:
      locationType = LocationType.OWNER_CUSTOM;
      break;
    case EventLocationType.CUSTOMER:
      locationType = LocationType.CUSTOM;
  }

  return {
    locationType,
    formattedAddress: eventLocation.address as string,
  };
};

const mapSessionLocationToLocation = (
  sessionLocation: SessionLocation,
): Location => {
  return {
    locationType: sessionLocation?.locationType as LocationType,
    formattedAddress:
      sessionLocation.customAddress?.formattedAddress ||
      sessionLocation.businessLocation?.address?.formattedAddress ||
      '',
    ...(sessionLocation.businessLocation?.id
      ? { id: sessionLocation.businessLocation?.id }
      : {}),
    ...(sessionLocation.businessLocation?.name
      ? { name: sessionLocation.businessLocation?.name }
      : {}),
  };
};
