import { IHttpClient } from '@wix/yoshi-flow-editor';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { FormValues } from '@wix/form-viewer';
import { SlotService } from '../../../utils/state/types';
import { createMultiServiceBooking } from '@wix/ambassador-bookings-v2-booking/http';
import {
  Booking,
  ContactDetails,
  CreateMultiServiceBookingRequest,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import {
  createBookingEntity,
  createParticipantNotificationObject,
} from './utils';

export const sendCreateMultiServiceBookingsRequest = async (
  httpClient: IHttpClient,
  slotServices: SlotService[],
  wixFormValues: FormValues,
  numberOfParticipants: number | undefined,
  memberDetails?: Member,
): Promise<{
  bookingIds: { [key: string]: string };
  contactDetails: ContactDetails;
}> => {
  const bookings: Booking[] = mapToArray<SlotService>(slotServices).map(
    (slotService) => {
      return createBookingEntity({
        memberDetails,
        slotService,
        numberOfParticipants,
      });
    },
  );

  const createMultiServiceBookingsRequest: CreateMultiServiceBookingRequest = {
    returnFullEntity: true,
    formSubmission: wixFormValues,
    bookings,
    ...createParticipantNotificationObject(),
  };
  const createGroupBookingsResult = await httpClient.request(
    createMultiServiceBooking(createMultiServiceBookingsRequest),
  );

  const bookingIds: { [key: string]: string } = {};
  if (
    createGroupBookingsResult.data.multiServiceBooking &&
    createGroupBookingsResult.data.multiServiceBooking.bookings &&
    createGroupBookingsResult.data.multiServiceBooking.bookings.length
  ) {
    slotServices.forEach((slotService: SlotService, index: number) => {
      bookingIds[getServiceSlotIdentifier(slotService.nestedSlot)] =
        createGroupBookingsResult.data.multiServiceBooking!.bookings![
          index
        ].bookingId!;
    });
  }

  return {
    contactDetails:
      createGroupBookingsResult?.data?.multiServiceBooking?.bookings?.[0]
        ?.booking?.contactDetails!,
    bookingIds,
  };
};
