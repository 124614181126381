export const enum FormStatus {
  IDLE = 'idle',
  SSR = 'ssr',
  PROCESSING_USER_DETAILS = 'processing-user-details',
  PROCESSING_PAYMENT_DETAILS = 'processing-payment-details',
  PROCESSING_SUBMIT_ACTION = 'processing-submit-action',
  INITIALIZING = 'initializing',
  REINITIALIZING = 'reinitializing',
  GENERAL_PROCESS = 'general-process',
}
