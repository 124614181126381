import { ServicePayment } from '../../../mappers/serviceMapper/utils/payment.mapper';
import {
  PaymentMethod,
  PlanWithDescription,
  ReservedPaymentOptionIds,
} from '../../../../types/types';
import settingsParams from '../../../../components/BookingsForm/settingsParams';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import {
  isOfferedAsOneTimeOnly,
  isOfferedAsPricingPlanOnly,
  isServiceHavePricingPlans,
} from '../utils';

export const getDefaultPaymentOptionId = ({
  settings,
  servicePayment,
  memberships,
  isPricingPlanInstalled,
  isDynamicPricingCustomOptions,
  possiblePlans,
}: {
  settings: ControllerFlowAPI['settings'];
  servicePayment: ServicePayment;
  isPricingPlanInstalled: boolean;
  memberships?: ListEligibleMembershipsResponse;
  isDynamicPricingCustomOptions?: boolean;
  possiblePlans?: PlanWithDescription[];
}) => {
  if (isDynamicPricingCustomOptions) {
    return ReservedPaymentOptionIds.SingleSession;
  }
  const planId = memberships?.eligibleMemberships?.[0]?.id; // todo: change to Selected Plan when implemented

  if (planId) {
    return planId;
  }
  if (isOfferedAsOneTimeOnly(servicePayment) || !isPricingPlanInstalled) {
    return ReservedPaymentOptionIds.SingleSession;
  }
  if (isOfferedAsPricingPlanOnly(servicePayment)) {
    return ReservedPaymentOptionIds.BuyAPricingPlan;
  }

  const selected =
    [
      PaymentMethod.SINGLE,
      PaymentMethod.ONLINE,
      PaymentMethod.OFFLINE,
    ].includes(settings.get(settingsParams.defaultPaymentMethod)) ||
    !isServiceHavePricingPlans(servicePayment) ||
    possiblePlans?.length === 0
      ? ReservedPaymentOptionIds.SingleSession
      : ReservedPaymentOptionIds.BuyAPricingPlan;
  return selected;
};
