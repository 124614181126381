import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import {
  FormOverrides,
  FormValues,
  MultilineAddressSubmission,
} from '@wix/form-viewer';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { getFieldsValuesInStorage } from '../../storageFunctions';
import { BookingsQueryParams } from '../../flow-api-adapter/types';
import { ContactDetails } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { WixFormFields } from '../../../types/wix-forms';
import { FormState, WixFormsFieldProperties } from '../types';
import { FormStatus } from '../../../types/form-state';

export interface CalculateWixFormInitialValues {
  currentState: Partial<FormState>;
  bookingsDataCapsule: BookingsDataCapsule;
  memberDetails?: Member;
  bookingsLineItemOptions?: BookingsLineItemOption[];
  fieldProperties: WixFormsFieldProperties;
}

interface GetDefaultValueProps extends CalculateWixFormInitialValues {
  preFilledValues: Maybe<FormValues>;
  contactDetailsFromFirstBookingOnCart: Maybe<ContactDetails>;
}

export const calculateWixFormInitialValues = async (
  data: CalculateWixFormInitialValues,
): Promise<{
  initialValues: FormValues;
  overrides: FormOverrides;
}> => {
  const { bookingsDataCapsule, bookingsLineItemOptions } = data;

  const preFilledValues: Maybe<FormValues> = await getFieldsValuesInStorage(
    bookingsDataCapsule,
    BookingsQueryParams.WIX_FORM_VALUES,
  );

  const contactDetailsFromFirstBookingOnCart = bookingsLineItemOptions?.length
    ? (bookingsLineItemOptions?.[0]?.contactDetails as ContactDetails)
    : undefined;

  const defaultValueProps: GetDefaultValueProps = {
    ...data,
    preFilledValues,
    contactDetailsFromFirstBookingOnCart,
  };

  const firstName = getFirstNameDefaultValue(defaultValueProps);
  const lastName = getLastNameDefaultValue(defaultValueProps);
  const email = getEmailDefaultValue(defaultValueProps);
  const phone = getPhoneDefaultValue(defaultValueProps);
  const address = getAddressDefaultValue(defaultValueProps);

  return {
    initialValues: {
      ...(firstName.initialValue
        ? { [WixFormFields.FIRST_NAME]: firstName.initialValue }
        : {}),
      ...(lastName?.initialValue
        ? { [WixFormFields.LAST_NAME]: lastName.initialValue }
        : {}),
      ...(email.initialValue
        ? { [WixFormFields.EMAIL]: email.initialValue }
        : {}),
      ...(phone.initialValue
        ? { [WixFormFields.PHONE]: phone.initialValue }
        : {}),
      ...(address?.initialValue
        ? { [WixFormFields.ADDRESS]: address.initialValue }
        : {}),
      ...getAdditionalFieldsValues(defaultValueProps),
    },
    overrides: {
      [WixFormFields.FIRST_NAME]: {
        readOnly: firstName.isReadonly,
      },
      ...(lastName
        ? { [WixFormFields.LAST_NAME]: { readOnly: lastName.isReadonly } }
        : {}),
      [WixFormFields.EMAIL]: {
        readOnly: email.isReadonly,
      },
      [WixFormFields.PHONE]: {
        readOnly: phone.isReadonly,
      },
      ...(address
        ? { [WixFormFields.ADDRESS]: { readOnly: address.isReadonly } }
        : {}),
    },
  };
};

const getFirstNameDefaultValue = ({
  memberDetails,
  preFilledValues,
  fieldProperties,
  contactDetailsFromFirstBookingOnCart,
  currentState,
}: GetDefaultValueProps): { initialValue: string; isReadonly: boolean } => {
  let initialValue: string = '';

  if (
    fieldProperties?.[WixFormFields.LAST_NAME] &&
    !fieldProperties?.[WixFormFields.LAST_NAME]?.hidden
  ) {
    if (contactDetailsFromFirstBookingOnCart?.firstName) {
      initialValue = contactDetailsFromFirstBookingOnCart.firstName;
    } else if (
      preFilledValues?.[WixFormFields.FIRST_NAME] &&
      currentState.status === FormStatus.INITIALIZING
    ) {
      initialValue = preFilledValues?.[WixFormFields.FIRST_NAME] as string;
    } else if (memberDetails?.contact?.firstName) {
      initialValue = memberDetails.contact.firstName;
    }
  } else {
    if (contactDetailsFromFirstBookingOnCart?.firstName) {
      initialValue = `${
        contactDetailsFromFirstBookingOnCart?.firstName || ''
      } ${contactDetailsFromFirstBookingOnCart?.lastName || ''}`.trim();
    } else if (
      preFilledValues?.[WixFormFields.FIRST_NAME] &&
      currentState.status === FormStatus.INITIALIZING
    ) {
      initialValue = preFilledValues?.[WixFormFields.FIRST_NAME] as string;
    } else if (memberDetails?.contact?.firstName) {
      initialValue = `${memberDetails?.contact?.firstName || ''} ${
        memberDetails?.contact?.lastName || ''
      }`.trim();
    }
  }

  return {
    initialValue,
    isReadonly: !!contactDetailsFromFirstBookingOnCart && !!initialValue,
  };
};

const getLastNameDefaultValue = ({
  memberDetails,
  preFilledValues,
  fieldProperties,
  contactDetailsFromFirstBookingOnCart,
  currentState,
}: GetDefaultValueProps): Maybe<{
  initialValue: string;
  isReadonly: boolean;
}> => {
  if (
    fieldProperties?.[WixFormFields.LAST_NAME] &&
    !fieldProperties?.[WixFormFields.LAST_NAME]?.hidden
  ) {
    let initialValue = '';

    if (contactDetailsFromFirstBookingOnCart?.lastName) {
      initialValue = contactDetailsFromFirstBookingOnCart.lastName;
    } else if (
      preFilledValues?.[WixFormFields.LAST_NAME] &&
      currentState.status === FormStatus.INITIALIZING
    ) {
      initialValue = preFilledValues?.[WixFormFields.LAST_NAME] as string;
    } else if (memberDetails?.contact?.lastName) {
      initialValue = memberDetails.contact.lastName;
    }

    return {
      initialValue,
      isReadonly: !!contactDetailsFromFirstBookingOnCart && !!initialValue,
    };
  }
};

const getEmailDefaultValue = ({
  memberDetails,
  preFilledValues,
  contactDetailsFromFirstBookingOnCart,
}: GetDefaultValueProps): { initialValue: string; isReadonly: boolean } => {
  let initialValue = '';

  const memberEmail =
    memberDetails?.loginEmail || memberDetails?.contact?.emails?.[0];
  const emailFromCart = contactDetailsFromFirstBookingOnCart?.email;

  if (emailFromCart) {
    initialValue = emailFromCart;
  } else if (memberEmail) {
    initialValue = memberEmail;
  } else if (preFilledValues?.[WixFormFields.EMAIL]) {
    initialValue = preFilledValues?.[WixFormFields.EMAIL] as string;
  }

  return {
    initialValue,
    isReadonly: !!(memberEmail || emailFromCart),
  };
};

const getPhoneDefaultValue = ({
  memberDetails,
  preFilledValues,
  contactDetailsFromFirstBookingOnCart,
  currentState,
}: GetDefaultValueProps): { initialValue: string; isReadonly: boolean } => {
  let initialValue = '';

  if (contactDetailsFromFirstBookingOnCart?.phone) {
    initialValue = contactDetailsFromFirstBookingOnCart?.phone || '';
  } else if (
    preFilledValues?.[WixFormFields.PHONE] &&
    currentState.status === FormStatus.INITIALIZING
  ) {
    initialValue = preFilledValues?.[WixFormFields.PHONE] as string;
  } else if (memberDetails?.contact?.phones?.[0]) {
    initialValue = memberDetails.contact.phones[0];
  }

  return {
    initialValue: initialValue.replace(/[\s-]/g, ''),
    isReadonly:
      !!contactDetailsFromFirstBookingOnCart?.phone &&
      hasCountryCode(initialValue),
  };
};

const getAddressDefaultValue = ({
  fieldProperties,
  currentState,
  preFilledValues,
}: GetDefaultValueProps): Maybe<{
  initialValue: MultilineAddressSubmission;
  isReadonly: boolean;
}> => {
  if (
    fieldProperties?.[WixFormFields.ADDRESS] &&
    !fieldProperties[WixFormFields.ADDRESS].hidden
  ) {
    if (
      preFilledValues?.[WixFormFields.PHONE] &&
      currentState.status === FormStatus.INITIALIZING
    ) {
      return {
        initialValue: preFilledValues?.[
          WixFormFields.ADDRESS
        ] as MultilineAddressSubmission,
        isReadonly: false,
      };
    }
  }
};

const getAdditionalFieldsValues = ({
  preFilledValues,
  currentState,
  fieldProperties,
}: GetDefaultValueProps): Maybe<FormValues> => {
  if (currentState.status === FormStatus.INITIALIZING) {
    return Object.keys(preFilledValues || {})
      .filter(
        (key) =>
          !Object.values(WixFormFields).includes(key as WixFormFields) &&
          Object.keys(fieldProperties).includes(key),
      )
      .reduce((acc, key) => {
        if (preFilledValues?.[key]) {
          acc[key] = preFilledValues?.[key];
        }
        return acc;
      }, {} as { [key: string]: any });
  }
};

const hasCountryCode = (phoneNumber: string): boolean => {
  const countryCodeRegex = /^\+\d+/;
  return countryCodeRegex.test(phoneNumber);
};
