import { FormValues } from '@wix/form-viewer';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ContactDetails } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { mapToArray } from '../../../utils';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { FormApiContext } from '../utils';
import { sendCreateMultiServiceBookingsRequest } from './sendCreateMultiServiceBookingsRequest';
import { sendCreateBookingsRequest } from './sendCreateBookingsRequest';

type CreateBookingsArgs = {
  memberDetails?: Member;
  serviceData: ServiceData;
  wixFormValues: FormValues;
  numberOfParticipants: number | undefined;
};

export const createBookings = async ({
  httpClient,
  serviceData,
  wixFormValues,
  memberDetails,
  numberOfParticipants,
  experiments,
}: CreateBookingsArgs & FormApiContext): Promise<{
  bookingIds: { [key: string]: string };
  contactDetails: ContactDetails;
}> => {
  if (serviceData.isSingleService) {
    const response = await sendCreateBookingsRequest(
      httpClient,
      mapToArray<SlotService>(serviceData.slotServices),
      wixFormValues,
      numberOfParticipants,
      memberDetails,
    );
    return response;
  } else {
    const response = await sendCreateMultiServiceBookingsRequest(
      httpClient,
      mapToArray<SlotService>(serviceData.slotServices),
      wixFormValues,
      numberOfParticipants,
      memberDetails,
    );

    return response;
  }
};
