import { FormValues } from '@wix/form-viewer';
import { Member } from '@wix/ambassador-members-ng-api/types';
import {
  Booking,
  ContactDetails,
  BulkCreateBookingRequest,
  BulkCreateBookingResponse,
  CreateBookingInfo,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { bulkCreateBooking } from '@wix/ambassador-bookings-v2-booking/http';
import {
  createBookingEntity,
  createParticipantNotificationObject,
} from './utils';
import { SlotService } from '../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../utils';
import { PlatformServerError } from '../../../../__tests__/mocks/ambassador/mockPlatformError';

export const sendCreateBookingsRequest = async (
  httpClient: IHttpClient,
  slotServices: SlotService[],
  wixFormValues: FormValues,
  numberOfParticipants: number | undefined,
  memberDetails?: Member,
): Promise<{
  bookingIds: { [key: string]: string };
  contactDetails: ContactDetails;
}> => {
  const singleRequests: CreateBookingInfo[] = slotServices.map(
    (slotService: SlotService) => {
      const booking: Booking = createBookingEntity({
        memberDetails,
        slotService,
        numberOfParticipants,
      });
      return {
        formSubmission: wixFormValues,
        booking,
        ...createParticipantNotificationObject(),
      };
    },
  );

  const bulkCreateBookingRequest: BulkCreateBookingRequest = {
    returnFullEntity: true,
    createBookingsInfo: singleRequests,
  };
  const requestRes = await httpClient.request(
    bulkCreateBooking(bulkCreateBookingRequest),
  );

  if (requestRes?.data?.bulkActionMetadata?.totalFailures) {
    throwPlatformServerError(requestRes.data);
  }

  const bookingIds: { [key: string]: string } = {};
  if (requestRes.data.results?.length) {
    slotServices.forEach((slotService: SlotService, index: number) => {
      bookingIds[getServiceSlotIdentifier(slotService.nestedSlot)] =
        requestRes.data.results![index]?.itemMetadata?.id!;
    });
  }

  return {
    contactDetails: requestRes?.data?.results?.[0]?.item?.contactDetails!,
    bookingIds,
  };
};

const throwPlatformServerError = (requestRes: BulkCreateBookingResponse) => {
  const error = requestRes?.results?.find(
    (result) => !result.itemMetadata?.success,
  )?.itemMetadata?.error;
  const platformServerError: PlatformServerError = {
    details: {
      applicationError: {
        code: error?.code,
      },
    },
    message: error?.description,
  };

  throw {
    response: platformServerError,
  };
};
