import { initFormController } from '@wix/form-viewer/controller';
import { FormApiContext } from '../utils';
import { WixFormsFieldProperties } from '../../../utils/state/types';

export const BOOKINGS_WIX_FORM_NAMESPACE = 'wix.bookings.v2.bookings';

export type InitWixFormControllerArgs = {
  formId: string;
  serviceIds?: string[];
};

export const initWixFormController = async ({
  flowApi,
  formId,
  serviceIds,
}: InitWixFormControllerArgs &
  FormApiContext): Promise<WixFormsFieldProperties> => {
  const { getFieldPropertiesByTarget } = await initFormController(flowApi, {
    formId,
    namespace: BOOKINGS_WIX_FORM_NAMESPACE,
    ...(serviceIds
      ? {
          additionalMetadata: {
            serviceIds,
          },
        }
      : {}),
  });

  return getFieldPropertiesByTarget(formId) as WixFormsFieldProperties;
};
