import { PriceUtils } from '@wix/bookings-uou-mappers';
import { Experiments } from '@wix/yoshi-flow-editor';
import {
  PaymentMethod,
  PaymentOption,
  TFunction,
} from '../../../../types/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { SlotService } from '../../../state/types';
import {
  createBuyPlanOption,
  createCustomPriceOption,
  createFreeOption,
  createPayOfflineOption,
  createPayOnlineOption,
  createUsePlanOption,
  isOfferedAsOneTime,
} from '..';
import { isServiceOfferedAsPricingPlan } from '../../../mappers/serviceMapper/utils/payment.mapper';

export const getSlotServicePaymentOptions = ({
  slotService,
  numberOfParticipants,
  isPricingPlanInstalled,
  t,
  dateAndTimeFormatter,
  dateRegionalSettingsLocale,
}: {
  slotService: SlotService;
  numberOfParticipants: number;
  isPricingPlanInstalled: boolean;
  t: TFunction;
  dateAndTimeFormatter: DateTimeFormatter;
  dateRegionalSettingsLocale: string;
  experiments: Experiments;
}): PaymentOption[] => {
  const options: PaymentOption[] = slotService.memberships?.eligibleMemberships
    ?.length
    ? slotService.memberships.eligibleMemberships!.map((membership) => {
        return createUsePlanOption(
          membership,
          numberOfParticipants,
          t,
          dateAndTimeFormatter,
        );
      })
    : [];

  const showBuyAPricingPlan = isServiceOfferedAsPricingPlan(
    slotService.service.payment,
    isPricingPlanInstalled,
  );

  if (options.length === 0 && showBuyAPricingPlan) {
    slotService.possiblePlans?.forEach((plan) => {
      options.push(createBuyPlanOption(plan));
    });
  }
  const showPaySingleSession = isOfferedAsOneTime(slotService.service.payment);

  if (showPaySingleSession) {
    let price = slotService.paymentDetails.price;
    if (slotService.dynamicPriceInfo?.selectedVariants?.length) {
      const selectedVariant = slotService.dynamicPriceInfo?.selectedVariants[0];

      const priceString =
        slotService.dynamicPriceInfo?.serviceOptionsAndVariants?.variants?.values?.find(
          (value) =>
            value.choices?.find(
              (choice) =>
                (choice.custom &&
                  choice.custom === selectedVariant?.choices?.[0].custom) ||
                (choice.staffMemberId &&
                  choice.staffMemberId ===
                    selectedVariant?.choices?.[0].staffMemberId) ||
                (choice.duration &&
                  choice.duration.minutes ===
                    selectedVariant?.choices?.[0].duration?.minutes),
            ),
        )?.price?.value!;

      price = parseInt(priceString, 10);
    }
    if (slotService.service.paymentTypes.includes(PaymentMethod.ONLINE)) {
      options.push(
        createPayOnlineOption(
          PriceUtils.getFormattedCurrency({
            price,
            currency: slotService.paymentDetails.currency,
            locale: dateRegionalSettingsLocale,
            currencyDisplay: 'narrowSymbol',
          }),
          t,
        ),
      );
    }
    if (slotService.service.paymentTypes.includes(PaymentMethod.OFFLINE)) {
      const isFree = slotService.service.payment.paymentDetails.isFree;
      const customPrice = slotService.service.payment.paymentDetails.priceText;
      if (isFree) {
        if (customPrice) {
          options.push(createCustomPriceOption(customPrice));
        } else {
          options.push(createFreeOption());
        }
      } else {
        options.push(
          createPayOfflineOption(
            PriceUtils.getFormattedCurrency({
              price,
              currency: slotService.paymentDetails.currency,
              locale: dateRegionalSettingsLocale,
              currencyDisplay: 'narrowSymbol',
            }),
            t,
          ),
        );
      }
    }
  }

  return options;
};
