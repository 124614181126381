import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import {
  BookedSchedule,
  Booking,
  BookingSourceActor,
  BookingSourcePlatform,
  ParticipantChoices,
  SelectedPaymentOption,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormNestedSlot } from '../../../types/formSelectedSlots';
import { mapFormNestedSlotToServerSlot } from '../../../utils/mappers/ambassador-bookings-v2.mapper';
import { mapDynamicParticipantsNumber } from '../../../utils/mappers/dynamic-price.mapper';
import { FormService } from '../../../utils/mappers/serviceMapper/service.mapper';
import { SlotService } from '../../../utils/state/types';
import { ParticipantNotification } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';

export const createParticipantNotificationObject = (): {
  participantNotification: ParticipantNotification;
  sendSmsReminder: boolean;
} => {
  return {
    participantNotification: {
      notifyParticipants: true,
      metadata: { channels: 'EMAIL,SMS' },
    },
    sendSmsReminder: true,
  };
};

export const createBookingEntity = ({
  slotService,
  memberDetails,
  numberOfParticipants,
}: {
  slotService: SlotService;
  memberDetails?: Member;
  numberOfParticipants: number | undefined;
}): Booking => {
  const selectedMembership = slotService.memberships?.eligibleMemberships?.find(
    (membership) => membership?.id === slotService.selectedPaymentOption.id,
  );
  const { participantsChoices, totalParticipants } =
    mapDynamicParticipantsNumber(
      slotService.dynamicPriceInfo?.selectedVariants,
    );
  const isCourse = slotService.service.type === ServiceType.COURSE;

  return {
    contactDetails: {
      contactId: memberDetails?.contactId,
    },
    v2Availability: slotService?.nestedSlot?.v2Availability || false,
    ...getParticipantsField(
      participantsChoices,
      totalParticipants,
      numberOfParticipants,
    ),
    bookingSource: {
      actor: BookingSourceActor.CUSTOMER,
      platform: BookingSourcePlatform.WEB,
    },
    selectedPaymentOption: (selectedMembership
      ? SelectedPaymentOption.MEMBERSHIP
      : slotService.selectedPaymentType) as SelectedPaymentOption,
    bookedEntity: {
      ...(isCourse
        ? {
            schedule: mapBookedSchedule({
              service: slotService.service,
              slot: slotService.nestedSlot,
            }),
          }
        : {
            slot: mapFormNestedSlotToServerSlot(slotService.nestedSlot),
          }),
    },
  };
};

const mapBookedSchedule = ({
  service,
  slot,
}: {
  service: FormService;
  slot: FormNestedSlot;
}): BookedSchedule => {
  return {
    serviceId: service.id,
    scheduleId: service.scheduleId,
    timezone: slot.timezone,
  };
};

const getParticipantsField = (
  participantsChoices?: ParticipantChoices,
  totalParticipants?: number,
  numberOfParticipants?: number,
) => {
  if (participantsChoices) {
    return { participantsChoices };
  }
  if (totalParticipants) {
    return { totalParticipants };
  }
  return { numberOfParticipants: numberOfParticipants || 1 };
};
