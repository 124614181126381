import { OnError } from '../../types';
import { FormApiContext } from '../utils';

export type AreCouponsAvailableForServiceArgs = {
  onError: OnError;
};
export const areCouponsAvailableForService = async ({
  couponsServer,
  authorization,
}: AreCouponsAvailableForServiceArgs & FormApiContext): Promise<boolean> => {
  try {
    const { hasCoupons } = await couponsServer
      .CouponsV2()({ Authorization: authorization })
      .hasCoupons({});
    return hasCoupons;
  } catch (e) {
    console.error('Error checking coupons availability', (e as any).message);
    return false;
  }
};
