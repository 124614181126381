import { getBookingsAutomationSettings } from '@wix/ambassador-bookings-v1-bookings-automation-settings/http';
import { FormApiContext } from '../utils';
import {
  BookingsAutomationComponentIds,
  BookingsAutomationStatus,
} from '@wix/ambassador-bookings-v1-bookings-automation-settings/types';
import { BusinessInfo } from '../../../types/types';

export interface IsSMSAutomationsEnabledArgs {
  businessInfo: BusinessInfo;
}

export const isSMSAutomationsEnabled = async ({
  businessInfo,
  httpClient,
}: IsSMSAutomationsEnabledArgs & FormApiContext): Promise<boolean> => {
  if (businessInfo.isSMSReminderEnabled) {
    return true;
  }

  try {
    const { data } = await httpClient.request(
      getBookingsAutomationSettings({
        automationKeys: [
          BookingsAutomationComponentIds.SMS_APPOINTMENT_OR_CLASS_CHANGE,
          BookingsAutomationComponentIds.SMS_BOOK_APPROVED,
          BookingsAutomationComponentIds.SMS_BOOK_CANCELED,
          BookingsAutomationComponentIds.SMS_BOOK_CONFIRM,
          BookingsAutomationComponentIds.SMS_BOOK_DECLINED,
          BookingsAutomationComponentIds.SMS_BOOK_PENDING,
          BookingsAutomationComponentIds.SMS_COURSE_CHANGE,
          BookingsAutomationComponentIds.SMS_UPCOMING_REMINDER,
        ],
      }),
    );

    return !!data.automationSettings?.find(
      (settings) => settings.status === BookingsAutomationStatus.ACTIVE,
    );
  } catch (e) {
    console.error('Failed to call getBookingsAutomationSettings', e);
    return true;
  }
};
