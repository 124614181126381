import { FormStatus } from '../../../../types/form-state';
import { CreateActionParams } from '../actions';

export type SetFormStatus = (status: FormStatus) => void;

export function createSetFormStatusAction({
  getControllerState,
}: CreateActionParams): SetFormStatus {
  return async (status: FormStatus) => {
    const [, setState] = getControllerState();
    return setState({ status });
  };
}
