import { ServerErrorType } from '../../../types/errors';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { BusinessInfo, IncompleteFormSelectedSlot } from '../../../types/types';
import { mapBusinessResponseToBusinessInfo } from '../../../utils/mappers/businessInfo.mapper';
import { CatalogData, OnError, StaffMember } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';
import { query as resourceQuery } from '@wix/ambassador-bookings-catalog-v1-resource/http';
import { Resource } from '@wix/ambassador-bookings-catalog-v1-resource/types';
import { getAggregatedInfo } from '@wix/ambassador-bookings-catalog-v1-business-info/http';
import { getNotificationView } from '@wix/ambassador-bookings-notifications-v1-notifications-settings/http';

type GetCatalogDataArgs = {
  formSelectedSlot?: FormSelectedSlot;
  onError?: OnError;
};

export const getCatalogData = async ({
  reportError,
  formSelectedSlot,
  onError,
  httpClient,
  wixSdkAdapter,
}: GetCatalogDataArgs & FormApiContext): Promise<CatalogData> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const [
          servicesData,
          resources,
          businessInfoResponse,
          notificationSetupRespoonse,
        ] = await Promise.all([
          formSelectedSlot?.nestedSlots.length
            ? httpClient.request(
                queryServices({
                  query: {
                    filter: {
                      id: {
                        $in: formSelectedSlot?.nestedSlots.map(
                          (slot) => slot.serviceId,
                        ),
                      },
                    },
                  },
                }),
              )
            : Promise.resolve({ data: { services: [] } }),
          httpClient.request(
            resourceQuery({
              query: {
                filter: getResourcesFilter({
                  formSelectedSlot,
                }),
              },
            }),
          ),
          httpClient.request(getAggregatedInfo({})),
          httpClient.request(getNotificationView({})),
        ]);

        const services = servicesData.data.services;

        const businessInfo: BusinessInfo = mapBusinessResponseToBusinessInfo({
          businessResponse: businessInfoResponse.data,
          notificationSetupViewResponse: notificationSetupRespoonse.data,
          wixSdkAdapter,
        });

        const serviceList = services!.map((service) => {
          const slot = formSelectedSlot?.nestedSlots.find(
            (nestedSlot) => nestedSlot.serviceId === service?.id,
          );
          const serviceResources = resources.data.resources?.filter(
            (staffMember) => {
              return service.staffMemberIds?.includes(staffMember.id!);
            },
          );
          const serviceResourcesIds =
            serviceResources?.map((resource) => resource.id) || [];
          const relevantResources = resources.data.resources!.filter(
            (resource) =>
              slot?.resource?.id || serviceResourcesIds.includes(resource.id!),
          );
          const staffMembers = relevantResources.map(
            mapResourcesV2ResponseToStaffMember,
          );

          return {
            service,
            staffMembers,
          };
        });

        return {
          serviceList,
          businessInfo,
          activeFeatures: businessInfoResponse.data.activeFeatures!,
        };
      },
      mapError: (e) => ({
        error: ServerErrorType.INVALID_CATALOG_DATA,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }

  return data!;
};

const getResourcesFilter = ({
  formSelectedSlot,
}: {
  formSelectedSlot?: IncompleteFormSelectedSlot;
}): Record<string, any> => {
  const filter: { [key: string]: any } = {};
  let isMissingResource = false;
  const resourceIds =
    formSelectedSlot?.nestedSlots.map((slot) => {
      if (!slot.resource?.id) {
        isMissingResource = true;
      }
      return slot.resource?.id;
    }) || [];

  if (isMissingResource || resourceIds.length === 0) {
    filter['resource.tags'] = { $hasSome: ['staff'] };
  } else {
    filter['resource.id'] = { $in: resourceIds };
  }

  return filter;
};

function mapResourcesV2ResponseToStaffMember(
  resourceResponse: Resource,
): StaffMember {
  return {
    id: resourceResponse.id!,
    name: resourceResponse.name!,
  };
}
