import { FormValues } from '@wix/form-viewer';
import { CartFlow, FlowType } from '../../../../types/types';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import { FormStatus } from '../../../../types/form-state';
import handleChoosePlanFlow from './handleChoosePlanFlow';
import { handleOwnerFlow } from './handleOwnerFlow';
import { handlePreviewFlow } from './handlePreviewFlow';
import { handleTemplateFlow } from './handleTemplateFlow';
import { handleBookFlow } from './handleBookFlow';
import { IFlowApiAdapter } from '../../../../utils/flow-api-adapter/types';
import { bookingsUouBookingFormNextClickOnAction } from '@wix/bi-logger-wixboost-ugc/v2';

export type OnSubmit = ({
  wixFormValues,
  flowType,
  cartFlow,
}: {
  wixFormValues: FormValues;
  flowType?: FlowType;
  cartFlow?: CartFlow;
}) => Promise<void>;

export function createOnSubmitAction(
  actionFactoryParams: CreateActionParams,
): OnSubmit {
  return async ({
    wixFormValues,
    flowType = FlowType.SINGLE_SESSION,
    cartFlow,
  }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { wixSdkAdapter, bookingsDataCapsule, biLogger } =
      actionFactoryParams.context;
    const { status, serviceData, formSelectedSlot } = state;

    if (status === FormStatus.IDLE) {
      biLogger?.report(bookingsUouBookingFormNextClickOnAction({}));
      setState({
        status: FormStatus.PROCESSING_SUBMIT_ACTION,
      });

      await setFieldsValuesInStorage(bookingsDataCapsule, wixFormValues);

      if (flowType === FlowType.SINGLE_SESSION) {
        flowType = getBookFlowType(wixSdkAdapter);
      }

      switch (flowType) {
        case FlowType.CHOOSE_PLAN:
          return handleChoosePlanFlow(
            actionFactoryParams,
            serviceData,
            formSelectedSlot,
          );
        case FlowType.SINGLE_SESSION:
          return handleBookFlow(actionFactoryParams, wixFormValues, cartFlow);
        case FlowType.OWNER:
          return handleOwnerFlow(actionFactoryParams);
        case FlowType.PREVIEW:
          return handlePreviewFlow(actionFactoryParams);
        case FlowType.TEMPLATE:
          return handleTemplateFlow(actionFactoryParams);
      }
    }
  };
}

function getBookFlowType(wixSdkAdapter: IFlowApiAdapter): FlowType {
  if (wixSdkAdapter.isOwner() && !wixSdkAdapter.isPreviewMode()) {
    return FlowType.OWNER;
  } else if (wixSdkAdapter.isPreviewMode()) {
    return FlowType.PREVIEW;
  } else if (wixSdkAdapter.isTemplateMode()) {
    return FlowType.TEMPLATE;
  } else {
    return FlowType.SINGLE_SESSION;
  }
}
