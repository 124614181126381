import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { CartConflictErrorType, FormError } from '../../../types/errors';
import { findOverlappingResource } from '../../cart-conflict/cartConflict';
import type {
  FormNestedSlot,
  FormSelectedSlot,
} from '../../../types/formSelectedSlots';
import { SlotService } from '../types';

export interface CheckBookingConflictsAndPushErrorsProps {
  errors: FormError[];
  bookingsLineItemOptions?: BookingsLineItemOption[];
  firstSlotServices: SlotService;
  formSelectedSlot?: FormSelectedSlot;
}

export const checkBookingConflictsAndPushErrors = ({
  errors,
  bookingsLineItemOptions,
  firstSlotServices,
  formSelectedSlot,
}: CheckBookingConflictsAndPushErrorsProps): FormError[] => {
  if (bookingsLineItemOptions) {
    if (
      firstSlotServices.service.availableSpots === 0 &&
      (firstSlotServices.service.type === ServiceType.COURSE ||
        firstSlotServices.service.type === ServiceType.CLASS)
    ) {
      errors.push({
        errorType: CartConflictErrorType.OVERLAPPING_SLOTS_ON_CART_ERROR,
      });
    } else {
      const overlappingResource = findOverlappingResource(
        formSelectedSlot?.nestedSlots! as FormNestedSlot[],
        bookingsLineItemOptions,
      );

      if (overlappingResource) {
        errors.push({
          errorType: CartConflictErrorType.OVERLAPPING_SLOTS_ON_CART_ERROR,
        });
      }
    }
  }

  return errors;
};
