import { FormValues } from '@wix/form-viewer';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { CartFlow } from '../../../types/types';
import { mapCheckoutBookingError } from '../../../utils/errors/errors';
import { ServiceData } from '../../../utils/state/types';
import { addToCart } from '../addToCart/addToCart';
import { generalBookingCheckoutFlow } from '../generalBookingCheckoutFlow/generalBookingCheckoutFlow';
import { FormApiContext, withErrorBoundary } from '../utils';
import { createBookings } from './createBookings';
import { AddToCartResponse } from '@wix/ambassador-ecom-v1-cart/types';
import { OnError } from '../../types';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-ecom-v1-checkout/types';
import { ContactDetails } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export type CheckoutBookingArgs = {
  memberDetails?: Member;
  wixFormValues: FormValues;
  serviceData: ServiceData;
  numberOfParticipants?: number;
  isCart?: boolean;
  cartFlow?: CartFlow;
  onError?: OnError;
  country: string;
  couponCheckboxChecked?: boolean;
};

export type CheckoutBookingResponse = {
  contactDetails: ContactDetails;
  bookingIds?: { [key: string]: string };
  createCheckoutResponse?: CreateCheckoutResponse | CreateOrderResponse;
  addToCurrentCartResponse?: AddToCartResponse;
};

export const checkoutBooking = async ({
  reportError,
  wixFormValues,
  serviceData,
  numberOfParticipants,
  isCart,
  cartFlow,
  onError,
  country,
  couponCheckboxChecked,
  ...rest
}: CheckoutBookingArgs & FormApiContext): Promise<CheckoutBookingResponse> => {
  const { data: response, error: createBookingsError } =
    await withErrorBoundary(
      {
        fn: () =>
          createBookings({
            wixFormValues,
            serviceData,
            numberOfParticipants,
            reportError,
            ...rest,
          }),
        mapError: (e) => ({
          error: mapCheckoutBookingError(e?.response),
          shouldReport: true,
        }),
        fallback: {
          bookingIds: {},
          contactDetails: {},
        },
      },
      reportError,
    );

  if (createBookingsError) {
    onError?.(createBookingsError);
  }

  const { bookingIds, contactDetails } = response;

  if (
    isCart &&
    Object.keys(bookingIds).length &&
    cartFlow === CartFlow.ADD_MORE_SESSIONS
  ) {
    try {
      const result = await addToCart({
        serviceData,
        bookingIds,
        onError,
        reportError,
        ...rest,
      });

      return {
        contactDetails,
        ...result,
      };
    } catch (e: any) {
      if (e.httpStatus === 404) {
        const result = await addToCart({
          serviceData,
          bookingIds,
          onError,
          reportError,
          ...rest,
        });

        return {
          contactDetails,
          ...result,
        };
      } else {
        onError?.(e.message);
      }
    }
  } else {
    const result = await generalBookingCheckoutFlow({
      couponCheckboxChecked,
      serviceData,
      bookingIds,
      contactDetails,
      onError,
      isCart,
      country,
      reportError,
      ...rest,
    });

    return {
      contactDetails,
      ...result,
    };
  }

  return {
    contactDetails,
    bookingIds,
    createCheckoutResponse: {},
  };
};
