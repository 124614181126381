import {
  SelectedVariants,
  ServicePaymentDto,
  PaymentType,
} from '@wix/bookings-uou-types';
import {
  BookingLineItem,
  PriceInfo,
} from '@wix/ambassador-bookings-v2-price-info/types';
import type {
  ServiceChoice,
  ServiceOption,
  ServiceOptions,
  ServiceVariant,
  ServiceVariants,
  Money,
} from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { ServiceOptionType } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { PaymentDetails, CustomOption } from '../../types/dynamicPrice';
import { PaymentDtoMapper, PriceUtils } from '@wix/bookings-uou-mappers';
import { PriceChoice } from '../../components/BookingsForm/Widget/PriceOptionDropdownContainer/PriceOptionDropdown/PriceOptionDropdown';
import { ParticipantChoices } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export const mapVariantsToLineItems = ({
  serviceId,
  resourceId,
  selectedVariants,
}: {
  serviceId: string;
  resourceId: string;
  selectedVariants: SelectedVariants[];
}): BookingLineItem[] => {
  const bookingLineItems: BookingLineItem[] = selectedVariants.map(
    ({ numberOfParticipants, choices }) => {
      const staffMemberId = choices?.find(
        (choice: ServiceChoice) => choice?.staffMemberId,
      )?.staffMemberId;
      const customChoices = choices?.filter((choice) => isCustomChoice(choice));
      return {
        serviceId,
        resourceId: staffMemberId || resourceId,
        numberOfParticipants,
        choices: customChoices,
      };
    },
  );
  return bookingLineItems;
};

export const mapPriceInfoToPaymentDetails = ({
  priceInfo,
  dynamicPriceOptions,
  locale,
}: {
  priceInfo: PriceInfo;
  dynamicPriceOptions: ServiceOptions;
  locale: string;
}): PaymentDetails[] => {
  let paymentDetails: PaymentDetails[] = [];
  if (isCustomTypeOption(dynamicPriceOptions)) {
    paymentDetails =
      priceInfo.bookingLineItems?.map((bookingLineItem: BookingLineItem) => {
        return {
          numberOfParticipants: bookingLineItem.numberOfParticipants || 1,
          choice: bookingLineItem.choices?.[0]?.custom!,
          price: {
            value: String(bookingLineItem.pricePerParticipant),
            currency: priceInfo.currency!,
            formattedValue: PriceUtils.getFormattedCurrency({
              price: bookingLineItem.pricePerParticipant!,
              currency: priceInfo.currency!,
              locale,
            }),
          },
        };
      }) || [];
  }
  return paymentDetails;
};

function isCustomTypeOption(dynamicPriceOptions: ServiceOptions) {
  return (
    dynamicPriceOptions?.values?.length === 1 &&
    dynamicPriceOptions?.values?.[0].type === ServiceOptionType.CUSTOM
  );
}

function isCustomChoice(choice: ServiceChoice): boolean {
  return !!choice.custom || !!choice.duration;
}

export function isDynamicVariant(variant: SelectedVariants): boolean {
  return !!variant.choices?.find((choice) => isCustomChoice(choice));
}

export function mapDynamicParticipantsNumber(
  selectedVariants?: SelectedVariants[],
): {
  participantsChoices?: ParticipantChoices;
  totalParticipants?: number;
} {
  let participantsChoices: ParticipantChoices | undefined;
  let totalParticipants: number | undefined;
  if (selectedVariants) {
    const isCustomBased = isDynamicVariant(selectedVariants[0]);
    if (isCustomBased) {
      participantsChoices = {
        serviceChoices: selectedVariants,
      };
    } else {
      totalParticipants = selectedVariants?.[0]?.numberOfParticipants || 0;
    }
  }
  return {
    totalParticipants,
    participantsChoices,
  };
}

function serviceChoiceToDropdownOptionMapper({
  choice,
  price,
  dateRegionalSettingsLocale,
}: {
  choice: string;
  price?: Money;
  dateRegionalSettingsLocale?: string;
}): PriceChoice {
  let priceText = '';
  if (price && dateRegionalSettingsLocale) {
    const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
    const priceValue = Number(price.value!);

    const servicePaymentDto: ServicePaymentDto = {
      currency: price!.currency!,
      price: priceValue,
      isFree: priceValue === 0,
      paymentType: PaymentType.ONLINE, // arbitrary, not used
      minCharge: 0,
      priceText: '',
    };
    priceText = paymentDtoMapper.priceText(servicePaymentDto);
  }
  return {
    title: choice,
    choiceId: choice,
    ...(priceText ? { price: priceText } : {}),
  };
}

function mapSelectedVariantsToCustomOptions(
  customOptions: CustomOption[],
  selectedVariants: SelectedVariants[],
): CustomOption[] {
  const prepopulatedCustomOptions: CustomOption[] = customOptions;
  selectedVariants.forEach((variant) => {
    if (isDynamicVariant(variant)) {
      const optionId = variant.choices?.[0]?.optionId!;
      const selectedVariant = customOptions.find(
        (option) => option.optionId === optionId,
      );
      const custom: string = variant.choices?.[0]?.custom!;
      const userChoice = selectedVariant?.options.find(
        (choice) => choice.choiceId === custom,
      );
      if (!userChoice) {
        return;
      }
      userChoice!.numberOfParticipantsInitialValue =
        variant.numberOfParticipants;
    }
  });
  return prepopulatedCustomOptions;
}

function customServiceOptionsToCustomOptions({
  serviceOptions,
  serviceVariants,
  dateRegionalSettingsLocale,
}: {
  serviceOptions: ServiceOptions;
  serviceVariants?: ServiceVariants;
  dateRegionalSettingsLocale: string;
}): CustomOption[] {
  const customOptions: CustomOption[] = [];
  serviceOptions?.values
    ?.filter(
      (serviceOption: ServiceOption) =>
        serviceOption.type === ServiceOptionType.CUSTOM,
    )
    .forEach((serviceOption: ServiceOption) => {
      const filteredVariants = serviceVariants?.values?.filter(
        (variant: ServiceVariant) =>
          variant.choices?.[0].optionId === serviceOption.id &&
          !!variant.choices?.[0].custom,
      );
      customOptions.push({
        optionId: serviceOption.id!,
        name: serviceOption.customData?.name!,
        options:
          filteredVariants?.map((variant) =>
            serviceChoiceToDropdownOptionMapper({
              choice: variant.choices?.[0].custom!,
              price: variant.price!,
              dateRegionalSettingsLocale,
            }),
          ) || [],
      });
    });
  return customOptions;
}

export function selectedVariantsAndServiceOptionsToCustomOptions({
  selectedVariants = [],
  serviceOptions = { values: [] },
  serviceVariants = { values: [] },
  dateRegionalSettingsLocale = 'en-US',
}: {
  selectedVariants?: SelectedVariants[];
  serviceOptions?: ServiceOptions;
  serviceVariants?: ServiceVariants;
  dateRegionalSettingsLocale?: string;
}): CustomOption[] {
  let customOptions = customServiceOptionsToCustomOptions({
    serviceOptions,
    serviceVariants,
    dateRegionalSettingsLocale,
  });

  if (selectedVariants.length) {
    customOptions = mapSelectedVariantsToCustomOptions(
      customOptions,
      selectedVariants,
    );
  }

  return customOptions;
}

export function isPreferenceOption(dynamicPriceOptions: ServiceOptions) {
  return (
    dynamicPriceOptions?.values?.length === 1 &&
    dynamicPriceOptions?.values?.[0].type !== ServiceOptionType.CUSTOM
  );
}
