import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import settingsParams from '../../../components/BookingsForm/settingsParams';
import { PaymentMethod, PlanWithDescription } from '../../../types/types';
import { ControllerFlowAPI, Experiments } from '@wix/yoshi-flow-editor';
import { IFlowApiAdapter } from '../../flow-api-adapter/types';
import { BusinessInfoBase } from '../../mappers/business-info.mapper';

export const getServicePlans = (
  serviceId: string,
  plans?: PlanWithDescription[],
  benefitsWithPlanInfo?: BenefitWithPlanInfo[],
): PlanWithDescription[] => {
  if (!plans || !benefitsWithPlanInfo || !benefitsWithPlanInfo.length) {
    return [];
  }

  const servicePlans = benefitsWithPlanInfo
    .filter((b) => b.benefit?.resourceIds?.includes(serviceId))
    .map((b) => b.planInfo?.id);

  return plans.filter((p) => servicePlans.includes(p.id));
};

export const getDefaultPaymentType = (
  settings: ControllerFlowAPI['settings'],
  paymentTypes: PaymentMethod[],
): PaymentMethod => {
  const defaultPaymentType = settings.get(settingsParams.defaultPaymentMethod);
  if (paymentTypes.includes(defaultPaymentType)) {
    return defaultPaymentType;
  }

  if (paymentTypes.length === 1) {
    return paymentTypes[0];
  }

  return paymentTypes.filter(
    (paymentType) => paymentType !== PaymentMethod.MEMBERSHIP,
  )[0];
};

export async function isCartEnabled({
  wixSdkAdapter,
  businessInfo,
}: {
  experiments: Experiments;
  wixSdkAdapter: IFlowApiAdapter;
  businessInfo: BusinessInfoBase;
}) {
  return (await wixSdkAdapter.isCartEnabled(businessInfo)) || false;
}
