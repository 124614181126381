import {
  GetAggregatedInfoResponse,
  GetPropertiesResponse,
} from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import { TimezoneType } from '@wix/bookings-uou-types';
import { TimezoneProperties } from '@wix/bookings-uou-types/src';

export const DEFAULT_TIMEZONE = 'America/New_York';

export enum BusinessPropertyName {
  DEFAULT_TIMEZONE_PROPERTY_NAME = 'defaultTimezone',
  CLIENT_CAN_CHANGE_TIMEZONE = 'clientCanChangeTimezone',
  CANCELLATION_POLICY = 'cancellationPolicy',
  IS_CART_ENABLED = 'isCartEnabled',
  IS_MULTI_SERVICE_APPOINTMENTS_ENABLED = 'isMultiServicesAppointmentsEnable',
}

export interface BusinessInfoBase {
  name?: string | null;
  language?: string | null;
  currency?: string | null;
  timeZone?: string | null;
  timezoneProperties?: TimezoneProperties;
  regionalSettingsLocale?: string | null;
  dateRegionalSettingsLocale?: string | null;
  countryCode?: string | null;
  cancellationPolicy?: string | null;
  isCartEnabled?: boolean;
  isMultiServicesAppointmentsEnable?: boolean;
}

export function mapResponseToBusinessInfo(
  businessResponse: GetAggregatedInfoResponse,
): Maybe<BusinessInfoBase> {
  const businessInfo = businessResponse.infoView;
  const businessProperties = businessResponse;

  if (businessInfo && businessProperties) {
    const defaultTimezoneType = getDefaultTimezoneType(businessProperties);
    const clientCanChangeTimezone =
      getClientCanChangeTimezoneIndication(businessProperties);
    const cancellationPolicy = getCancellationPolicy(businessProperties);
    const isCartEnabled = getIsCartEnabled(businessProperties);
    const isMultiServicesAppointmentsEnable =
      getIsMultiServicesAppointmentsEnable(businessProperties);

    const dateRegionalSettingsLocale =
      formatDateRegionalSettingsLocale(
        businessInfo.language,
        businessInfo.countryCode,
      ) || 'en-US';

    return {
      name: businessInfo.name,
      language: businessInfo.language,
      currency: businessInfo.currency,
      regionalSettingsLocale: businessInfo.locale,
      dateRegionalSettingsLocale,
      timeZone: businessInfo.timeZone || DEFAULT_TIMEZONE,
      timezoneProperties: {
        clientCanChooseTimezone: clientCanChangeTimezone === 'true',
        defaultTimezone: defaultTimezoneType,
      },
      countryCode: businessInfo.countryCode,
      cancellationPolicy,
      isCartEnabled,
      isMultiServicesAppointmentsEnable,
    };
  }
}

function formatDateRegionalSettingsLocale(
  language?: string | null,
  countryCode?: string | null,
) {
  const locale = language?.split('-')[0];

  return `${locale}${countryCode ? `-${countryCode}` : ``}`;
}

export function getDefaultTimezoneType(
  businessProperties: GetPropertiesResponse,
) {
  return (
    getPropertyValue(
      businessProperties?.customProperties,
      BusinessPropertyName.DEFAULT_TIMEZONE_PROPERTY_NAME,
    ) || TimezoneType.BUSINESS
  );
}

export function getClientCanChangeTimezoneIndication(
  businessProperties: GetPropertiesResponse,
) {
  return (
    getPropertyValue(
      businessProperties?.customProperties,
      BusinessPropertyName.CLIENT_CAN_CHANGE_TIMEZONE,
    ) || 'true'
  );
}

export function getCancellationPolicy(
  businessProperties: GetPropertiesResponse,
) {
  return getPropertyValue(
    businessProperties?.customProperties,
    BusinessPropertyName.CANCELLATION_POLICY,
  );
}

export function getIsCartEnabled(businessProperties: GetPropertiesResponse) {
  const propertyValue = getPropertyValue(
    businessProperties?.customProperties,
    BusinessPropertyName.IS_CART_ENABLED,
  );
  return propertyValue === 'true';
}

export function getIsMultiServicesAppointmentsEnable(
  businessProperties: GetPropertiesResponse,
) {
  const propertyValue = getPropertyValue(
    businessProperties?.customProperties,
    BusinessPropertyName.IS_MULTI_SERVICE_APPOINTMENTS_ENABLED,
  );
  return propertyValue === 'true';
}

export function getPropertyValue(
  properties: any[] | undefined,
  propertyName: BusinessPropertyName,
) {
  const property = properties?.find(
    (businessProperty) => businessProperty.propertyName === propertyName,
  );
  return property?.value;
}
