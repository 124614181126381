import { CatalogData } from '../../../api/types';

export interface GetFormIdProps {
  catalogData?: CatalogData;
}

export const DEFAULT_FORM_ID = '00000000-0000-0000-0000-000000000000';

export const getFormId = ({ catalogData }: GetFormIdProps): string => {
  const firstServiceFormId = catalogData?.serviceList?.[0]?.service?.form?.id;

  if (catalogData?.serviceList?.length === 1) {
    return firstServiceFormId || DEFAULT_FORM_ID;
  }

  return DEFAULT_FORM_ID;
};
