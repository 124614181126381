import { FormApiContext } from '../utils';
import { LineItem as TotalsCalculatorLineItem } from '@wix/ambassador-totals-calculator/http';
import { CalculateTotalsResponse } from '@wix/ambassador-totals-calculator/types';
import { OnError } from '../../types';

export type CalculateTotalPriceArgs = {
  lineItems: TotalsCalculatorLineItem[];
  email?: string;
  onError?: OnError;
};
export const calculateTotalPrice = async ({
  authorization,
  totalsCalculatorServer,
  lineItems,
  email,
}: CalculateTotalPriceArgs &
  FormApiContext): Promise<CalculateTotalsResponse> => {
  const calculateTotalsResponse = await totalsCalculatorServer
    .TotalsCalculator()({
      Authorization: authorization,
    })
    .calculateTotals({
      calculateTax: true,
      buyerEmail: email,
      lineItems,
    });

  return calculateTotalsResponse;
};
