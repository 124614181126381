import {
  CalculatePaymentDetails,
  createCalculatePaymentDetailsAction,
} from './calculatePaymentDetails/calculatePaymentDetails';
import {
  createSetNumberOfParticipantsAction,
  SetNumberOfParticipants,
} from './setNumberOfParticipants/setNumberOfParticipants';
import { FormState } from '../../../utils/state/types';
import { FormContext } from '../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { createOnSubmitAction, OnSubmit } from './onSubmit/onSubmit';
import {
  createUpdateServiceSlotAction,
  UpdateServiceSlot,
} from './updateServiceSlot/updateServiceSlot';
import {
  createUpsellPluginSendBookingIdsAction,
  UpsellPluginSendBookingIdsAction,
} from './upsellPlugin/upsellPlugin';
import {
  createSetSelectedPaymentTypeAction,
  setSelectedPaymentType,
} from './setSelectedPaymentType/setSelectedPaymentType';
import {
  createPromptLoginAction,
  PromptLogin,
} from './promptLogin/promptLogin';
import { createOnLoginAction, OnLogin } from './onLogin/onLogin';
import {
  OnToastClose,
  createOnToastCloseAction,
} from './onToastClose/onToastClose';
import {
  OnDialogClose,
  createOnDialogCloseAction,
} from './onDialogClose/onDialogClose';
import {
  createInitializeWidgetAction,
  InitializeWidget,
} from './initializeWidget/initializeWidget';
import {
  createFormErrorHandlers,
  ErrorHandlers,
} from './errorHandlers/errorHandlers';
import {
  OnSelectedVariants,
  createOnSelectedVariantsAction,
} from './onSelectedVariants/onSelectedVariants';
import {
  createOnCartCTAClickAction,
  OnCartCTAClick,
} from './onCartCTAClick/onCartCTAClick';
import { createLogBiAction, logBi } from './logBi/logBi';
import { AddError } from './errorHandlers/addError/addError';
import { RemoveErrors } from './errorHandlers/removeError/removeErrors';
import { ClearErrorByTypes } from './errorHandlers/clearErrorByTypes/clearErrorByTypes';
import {
  createSetServiceSelectedPaymentOption,
  setServiceSelectedPaymentOptionType,
} from './setServiceSelectedPaymentOption/setServiceSelectedPaymentOption';
import {
  createSetDefaultPaymentOptionsAction,
  SetDefaultPaymentOptions,
} from './setDefaultPaymentOptions/setDefaultPaymentOptions';
import {
  OnConflictAlertShown,
  createOnConflictAlertShownAction,
} from './onConflictAlertShown/onConflictAlertShown';
import {
  createOpenCartDialogForEditorViewAction,
  OpenCartDialogForEditorView,
} from './openCartDialogForEditorView/openCartDialogForEditorView';
import {
  createOnCouponCheckboxCheckAction,
  OnCouponCheckboxCheck,
} from './onCouponCheckboxCheck';
import {
  CloseAlreadyBookedModal,
  createCloseAlreadyBookedModalAction,
} from './closeAlreadyBookedModal/closeAlreadyBookedModal';
import {
  createSetFormStatusAction,
  SetFormStatus,
} from './setFormStatus/setFormStatus';
import {
  createOnClickCloseCartErrorModalAction,
  createOnClickViewCartAction,
  OnClickCloseCartErrorModal,
  OnClickViewCart,
} from './cartErrorModalActions';

export type InternalFormActions = {
  errorHandlers: ErrorHandlers;
  calculatePaymentDetails: CalculatePaymentDetails;
  updateServiceSlot: UpdateServiceSlot;
};
export interface CreateActionParams
  extends ActionFactoryParams<FormState, FormContext> {
  internalActions: InternalFormActions;
}
export type FormControllerActions = {
  onSubmit: OnSubmit;
  onCartCTAClick: OnCartCTAClick;
  logBi: logBi;
  updateServiceSlot: UpdateServiceSlot;
  upsellPluginSendBookingIds: UpsellPluginSendBookingIdsAction;
  onLogin: OnLogin;
  promptLogin: PromptLogin;
  setNumberOfParticipants: SetNumberOfParticipants;
  onToastClose: OnToastClose;
  onDialogClose: OnDialogClose;
  initializeWidget: InitializeWidget;
  openCartDialogForEditorView: OpenCartDialogForEditorView;
  setSelectedPaymentType: setSelectedPaymentType;
  onSelectedVariants: OnSelectedVariants;
  addError: AddError;
  removeErrors: RemoveErrors;
  clearErrorByTypes: ClearErrorByTypes;
  setServiceSelectedPaymentOption: setServiceSelectedPaymentOptionType;
  setDefaultPaymentOptions: SetDefaultPaymentOptions;
  onConflictAlertShown: OnConflictAlertShown;
  onCouponCheckboxCheck: OnCouponCheckboxCheck;
  closeAlreadyBookedModal: CloseAlreadyBookedModal;
  setFormStatus: SetFormStatus;
  // Cart error modal actions:
  onClickViewCart: OnClickViewCart;
  onClickCloseCartErrorModal: OnClickCloseCartErrorModal;
};
export const createFormActions = (
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): FormControllerActions => {
  const updateServiceSlot = createUpdateServiceSlotAction(actionFactoryParams);
  const errorHandlers = createFormErrorHandlers(actionFactoryParams);
  const calculatePaymentDetails = createCalculatePaymentDetailsAction({
    actionFactoryParams,
    errorHandlers,
  });
  const internalActions: InternalFormActions = {
    errorHandlers,
    calculatePaymentDetails,
    updateServiceSlot,
  };
  const actionParams = { ...actionFactoryParams, internalActions };

  return {
    onSubmit: createOnSubmitAction(actionParams),
    updateServiceSlot: createUpdateServiceSlotAction(actionParams),
    upsellPluginSendBookingIds:
      createUpsellPluginSendBookingIdsAction(actionParams),
    promptLogin: createPromptLoginAction(actionParams),
    onCartCTAClick: createOnCartCTAClickAction(actionParams),
    logBi: createLogBiAction(actionParams),
    onLogin: createOnLoginAction(actionParams),
    setNumberOfParticipants: createSetNumberOfParticipantsAction(actionParams),
    onToastClose: createOnToastCloseAction(actionParams),
    onDialogClose: createOnDialogCloseAction(actionParams),
    initializeWidget: createInitializeWidgetAction(actionParams),
    openCartDialogForEditorView:
      createOpenCartDialogForEditorViewAction(actionParams),
    setSelectedPaymentType: createSetSelectedPaymentTypeAction(actionParams),
    onSelectedVariants: createOnSelectedVariantsAction(actionParams),
    setServiceSelectedPaymentOption:
      createSetServiceSelectedPaymentOption(actionParams),
    setDefaultPaymentOptions:
      createSetDefaultPaymentOptionsAction(actionParams),
    ...errorHandlers,
    onConflictAlertShown: createOnConflictAlertShownAction(actionParams),
    onCouponCheckboxCheck: createOnCouponCheckboxCheckAction(actionParams),
    closeAlreadyBookedModal: createCloseAlreadyBookedModalAction(actionParams),
    setFormStatus: createSetFormStatusAction(actionParams),
    onClickViewCart: createOnClickViewCartAction(actionParams),
    onClickCloseCartErrorModal:
      createOnClickCloseCartErrorModalAction(actionParams),
  };
};
