import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { mapCheckoutBookingError } from '../../../utils/errors/errors';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { OnError } from '../../types';
import {
  AddToCurrentCartRequest,
  LineItem,
} from '@wix/ambassador-ecom-v1-cart/types';
import { BOOKINGS_APP_DEF_ID } from '../../../utils/flow-api-adapter/consts';
import { addToCurrentCart as ecomAddToCurrentCart } from '@wix/ambassador-ecom-v1-cart/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getPaymentOption } from '../getPaymentOption/getPaymentOption';
import { FormApiContext, withErrorBoundary } from '../utils';
import { ExperimentsConsts } from '../../../consts/experiments';

export type AddToCartArgs = {
  serviceData: ServiceData;
  bookingIds?: { [key: string]: string };
  onError?: OnError;
};

export const addToCart = async ({
  experiments,
  httpClient,
  reportError,
  serviceData,
  bookingIds,
  onError,
}: AddToCartArgs & FormApiContext) => {
  const lineItems: LineItem[] = [];
  const slotServicesArray = mapToArray<SlotService>(serviceData.slotServices);
  const businessLocationId =
    slotServicesArray[0].nestedSlot?.location?.id ?? undefined;

  const isMultiLocationUoUEnabled = experiments.enabled(
    ExperimentsConsts.MultiLocationUoU,
  );

  slotServicesArray.forEach((slotService) => {
    const paymentDetails = slotService.paymentDetails;
    const paymentOption = getPaymentOption(
      slotService.selectedPaymentType,
      paymentDetails.minCharge,
    );
    const selectedMembership =
      slotService.memberships?.eligibleMemberships?.find(
        (membership) => membership?.id === slotService.selectedPaymentOption.id,
      );
    lineItems.push({
      paymentOption,
      ...(selectedMembership?.id
        ? {
            selectedMembership: {
              id: selectedMembership?.id,
              appId: selectedMembership?.appId,
            },
          }
        : {}),
      ...(paymentDetails.price
        ? {
            price: {
              amount: String(paymentDetails.price),
            },
          }
        : {}),
      ...(paymentDetails.priceText
        ? {
            priceDescription: {
              original: paymentDetails.priceText,
            },
          }
        : {}),
      quantity: 1,
      catalogReference: {
        catalogItemId:
          bookingIds![getServiceSlotIdentifier(slotService.nestedSlot)],
        appId: BOOKINGS_APP_DEF_ID,
      },
    });
  });
  const addToCurrentCartRequest: AddToCurrentCartRequest = {
    lineItems,
    ...(isMultiLocationUoUEnabled && businessLocationId
      ? { businessLocationId }
      : {}),
  };
  const {
    data: addToCurrentCartResponse,
    error: addToCurrentCartResponseError,
  } = await withErrorBoundary(
    {
      fn: () => addToCurrentCart(httpClient, addToCurrentCartRequest),
      mapError: (e) => ({
        error: mapCheckoutBookingError(e?.response),
        shouldReport: true,
      }),
      fallback: {},
    },
    reportError,
  );

  if (addToCurrentCartResponseError) {
    onError?.(addToCurrentCartResponseError);
  }
  if (addToCurrentCartResponse.cart) {
    return {
      addToCurrentCartResponse,
      bookingIds,
    };
  }
  return {
    addToCurrentCartResponse: {},
    bookingIds,
  };
};

const addToCurrentCart = async (
  httpClient: IHttpClient,
  addToCurrentCartRequest: AddToCurrentCartRequest,
) => {
  return (
    await httpClient.request(ecomAddToCurrentCart(addToCurrentCartRequest))
  ).data;
};
