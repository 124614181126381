import { ServerErrorType } from '../../../types/errors';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { ScheduleAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { getScheduleAvailability as httpGetScheduleAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/http';

export type GetScheduleAvailabilityArgs = {
  scheduleId: string;
  onError?: OnError;
};
export const getScheduleAvailability = async ({
  reportError,
  httpClient,
  scheduleId,
  onError,
}: GetScheduleAvailabilityArgs &
  FormApiContext): Promise<ScheduleAvailability> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const { availability } = (
          await httpClient.request(
            httpGetScheduleAvailability({
              scheduleId,
              shouldNotCallConsistentQuery: false,
            }),
          )
        ).data;
        return availability;
      },
      mapError: (e) => ({
        error: ServerErrorType.NO_COURSE_AVAILABILITY,
        shouldReport: true,
      }),
      fallback: {
        openSpots: 1,
      },
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }
  return data!;
};
