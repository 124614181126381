import { FormApiContext } from '../utils';
import { getEvent } from '@wix/ambassador-calendar-v3-event/http';
import { Event } from '@wix/ambassador-calendar-v3-event/types';

export type GetEventByIdArgs = {
  eventId: string;
};

export const getEventById = async ({
  eventId,
  httpClient,
}: GetEventByIdArgs & FormApiContext): Promise<Event | undefined> => {
  const response = await httpClient.request(
    getEvent({
      eventId,
    }),
  );

  return response.data.event;
};
